.terms-block {
  position: relative;
  background: $input-main-bg;
  border-radius: $border-radius-main;
  margin-bottom: $large-margin;
  padding: 10px 16px;

  &__title {
    font: 400 20px/24px $primary-font;
    color: $main-color;
    margin-bottom: $main-margin;
    text-align: center;
  }
  &__iframe {
    background: $white;
    border-radius: $border-radius-main;
    padding: 0;
    max-height: 300px;
    min-height: 300px;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }
  &__checkbox {
    .form-input-error {
      position: absolute;
      bottom: -21px;
    }
  }
  @media (max-width: $breakpoint-sm) {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__iframe {
      max-height: calc(100vh - 510px);
      min-height: 260px;
    }
  }
}
