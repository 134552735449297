.support-page {
  &-container {
    max-width: 1133px;
    border-top: 1px solid #DAEAFF;
    padding-top: 15px;
  }
  &-thanks{
    @include d-flex(center, center);
    flex-direction: column;
    row-gap: 47px;
    margin-top: 60px;
    & > svg {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }
    p{
      font: 400 22px/26px $primary-font;
      text-align: center;
      color: $white;
    }
  }
  &-text{
    font-size: 18px;
    color: #fff;
    margin-bottom: $medium-margin;
  }
  @media (max-width: $breakpoint-sm){
    &-container{
      flex: 1;
    }
    &-thanks{
      min-height: inherit;
      height: calc(100dvh - 216px);
      margin-top: 90px;
      row-gap: 30px;
      &.login {
        height: calc(100dvh - 265px);
      }
      & > svg {
        width: 40px;
        height: 40px;
      }
      p{
        max-width: 286px;
        flex: 1;
      }
      @supports (-webkit-touch-callout: none) {
        min-height: calc(100vh - 225px);
    }
    }
    .page-header__title-icon{
      justify-content: flex-start!important;
    }
    p{
      font-size: 14px;
      color: $main-color;
    }
  }   
}

.captha {
  margin-top: 30px;
  margin-bottom: 25px;
  @include d-flex($justify-content: flex-end);

  &.mobile-captcha {
    margin-top: 0;

    @media (max-width: $breakpoint-sm){
      justify-content: center;
    }  
  }

  @media (max-width: $breakpoint-sm){
    justify-content: flex-start;
  }  
}

.area-block {
  max-width: 586px;
  textarea {       
      width: 100%;
      height: 202px;
  }
  .btn {
      margin-left: auto;
  } 
}
