.agents-location-page {
  .agents-location-list {
    background-color: $table-body;
    border-radius: $border-radius-main;
    height: 100%;
    min-height: calc(100vh - 320px);
    max-height: calc(100vh - 400px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    color: $main-color;
    .agents-location-row {
      padding: 16px 24px;

      &:nth-child(odd) {
        background-color: $table-row;
      }
      .header-row {
        display: flex;
        justify-content: space-between;
        grid-gap: 30px;
        position: relative;
        font: 500 1rem/1.187 $primary-font;
        padding-right: 40px;
        margin-bottom: 12px;
        & > div:not(.agent-address){
          // max-width: 60%;
          // word-break: break-word;
        }
        .agent-address{
          text-align: end;
        }
        .coordinate-link {
          position: absolute;
          top: -7px;
          right: -15px;
          padding: 7px;
        }
      }
      p {
        font-size: 0.875rem;
        line-height: 1.14;
        &:not(.day-of-week):not(.closed-time){
          font: 700 0.75rem/1.166 $primary-font;
          margin-bottom: 8px;
        }
        &.day-of-week{
          margin-bottom: 8px;
        }
      }
      .body-row {
        display: flex;
        // justify-content: space-between;
        .body-cell {
          flex: 1;
          padding: 4px 8px;
          .closed-time {
            color: $danger;
          }
          p{
            font-size: 0.875rem;
            line-height: 1.14;
            &:not(.day-of-week){
              font-weight: 500;
            }
          }
         
        }
      }
    }
    .no-available{
      @include d-flex(center, center);
      flex: 1;
      font: 500 1.75rem/1.2 $primary-font;
    }
  }
  @media (min-width: $breakpoint-sm){
    .agents-location-list{
      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border: 4px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;  
      }
      
      &::-webkit-scrollbar {
        width: 16px;
      }
    }
  }
 
  @media (max-width: $breakpoint-lg) and (max-height: $breakpoint-xs){
    @media screen and (orientation:landscape) {
      .agents-location-list{
        min-height: 400px;
      }
    }
  }
  @media (max-width: $breakpoint-sm){
    height: 100%;
    .container{
      height: 100%;
    }
    .agents-location-list{
      min-height: calc(100% - 51px);
      gap: 8px;
      @supports (-webkit-touch-callout: none) {
        min-height: calc(100svh - 200px);
      }
      .agents-location-row{
        border: 1px solid $table-row;
        border-radius: 10px;
        padding: 0;
        &:nth-child(odd) {
          .header-row{
            div:not(.agent-address){
              border-bottom: 1px solid $table-body;
            }
          }
          .body-row{
            .body-cell{
              border-bottom: 1px solid $table-body;
            }
          }
        }
        & > p{
          padding: 0 16px;
        }
        .header-row{
          flex-direction: column;
          gap: 0;
          padding-right: 0;
          margin-bottom: 0;
          div:not(.agent-address){
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.15;
            padding: 9px 40px 10px 16px;
            border-bottom: 1px solid $table-row;
            word-wrap: break-word;
          }
          .agent-address{
            text-align: start;
            padding: 7px 16px 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }
          .coordinate-link{
            top: 6px;
            right: 9px;
          }
        }
        .body-row{
          flex-direction: column;
          padding: 0 24px 16px;
          .body-cell{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $table-row;
            padding: 4px 0;
            p{
              margin-bottom: 0;
              &.closed-time {
                font-weight: 400;
              }
            }
            

          }
        }
        
      }
    }
    
  }
}
