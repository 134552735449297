button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn {
  font: 500 18px/21px $primary-font;
  border-radius: $border-radius-main;
  background-color: $main-color;
  color: $white;
  padding: 12px 10px;
  height: 46px;
  text-align: center;
  white-space: nowrap;
  a {
    color: inherit;
  }
  //status
  &:disabled {
    color: $white;
    background-color: #95b7e5;
    cursor: not-allowed;
    &:hover {
      background-color: #95b7e5;
    }
  }
  &-additional {
    background-color: $btn-bg-additional;
  }

  // type
  &-primary {
    width: 100%;
  }
  &-privacy {
    font-weight: 500 !important;
    width: 100%;
    color: $text-color;
    background-color: $white;
    height: 30px;
    padding: 0 10px !important;
    border-radius: 26px;
    &:hover {
      color: $white;
    }
  }
  &-secondary {
    color: $text-color;
    background-color: $white;
    &:hover {
      background-color: #e6e6e6;
    }
  }

  &-back {
    max-width: 126px;
    width: 100%;
  }

  &-logIn,
  &-logOut {
    font: 400 14px/16px $primary-font;
    height: 40px;
    padding: 4px 10px;
  }

  &-logOut {
    @include d-flex(space-between, center);
    svg {
      margin-right: 4px;
    }
  }
  &-delete {
    background-color: $danger;
  }
  &-icon {
    @include d-flex($justify-content: unset);
    grid-gap: 8px;
    width: auto;
    font-size: 16px;
    &.bg-white {
      @include d-flex(center, center);
      width: 34px;
      height: 34px;
      background-color: #fff;
      border-radius: 50%;
      &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  &-cancel {
    color: $text-color;
    background-color: $white;
    max-width: 160px;
    width: 100%;
    // &:not(.btn-error):hover {
    //   background-color: #e6e6e6;
    // }
    &.btn-50 {
      max-width: calc(50% - 10px);
      @media (max-width: $breakpoint-sm) {
        padding-left: 2px;
        padding-right: 2px;
        font-size: 17px;
      }
    }
  }

  &-next {
    max-width: calc(100% - 180px);
    width: 100%;
    &.btn-50 {
      max-width: calc(50% - 10px);
    }
  }

  &-error {
    background-color: $error-bg;
    color: $error-color;
  }

  &-svg {
    position: relative;
    svg {
      position: absolute;
      //top: 8px;
      top: 13px;
      left: 13px;
      path {
        fill: #acccff;
      }
    }
  }

  // size
  &-xxs {
    padding: 2px;
    height: 30px;
    min-width: 85px;
  }

  &-xs {
    font-size: 14px;
    font-weight: 400;
    max-width: 125px;
  }

  &-sm {
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    max-width: 125px;
  }
  &-md {
    max-width: 180px;
    width: 100%;
  }

  &-xl {
    max-width: 240px;
    padding: 2px 10px;
    height: 32px;
  }

  &-xxl {
    font-size: 18px;
    font-weight: 700;
    max-width: 337px;
    width: 100%;
  }

  &-agree {
    border-radius: 26px;
    height: 30px;
    padding: 0 10px;
  }

  //types
  &-back {
    @include d-flex(center, center);
    color: $white;
    font-size: 18px;
    svg {
      margin-right: 6px;
      height: 16px;
      path {
        stroke: $white;
      }
    }
    @media (max-width: $breakpoint-sm) {
      background-color: transparent;
      margin-right: 8px;
      height: 32px;
      width: 24px;
      padding: 0;
      min-width: 24px;
      span {
        display: none;
      }
      svg {
        margin-right: 3px;
        path {
          stroke: $main-color;
        }
      }
    }
  }

  &-edit {
    font-size: 18px;
    font-weight: 500;
    color: $grey500;
    text-decoration: underline;
    margin-top: 10px;
    margin-left: auto;
    display: block;
  }
  &-noTransition {
    & > * {
      transition: unset;
    }
  }

  //btn wrapper
  &-wrapper {
    @include d-flex($justify-content: space-between);
    grid-gap: 15px;
  }
  &-wrapper-sm {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width: $breakpoint-sm + 1) {
    &:hover {
      background-color: $btn-bg-hover;
    }
    &-secondary,
    &-cancel {
      &:hover {
        background-color: $table-header;
      }
      &:disabled {
        color: $white;
        background-color: $table-header;
        cursor: not-allowed;
        &:hover {
          background-color: $table-header;
        }
      }
    }
    &-delete {
      &:hover {
        background-color: $danger;
        opacity: 0.8;
      }
    }
    &-error {
      &:hover {
        background-color: $error-bg;
      }
    }
    &-svg {
      svg {
        top: 13px;
      }
    }
  }
  @media (max-width: $breakpoint-sm) {
    &-logIn {
      background-color: $btn-bg-additional;
      background-color: transparent;
      color: $main-color;
      padding-top: 0;
      padding-bottom: 0;
      height: 32px;
    }
    &-primary {
      background-color: $btn-bg-additional;
      width: calc(100% - 32px) !important;
    }
    &-lookup {
      background-color: $btn-bg-additional;
    }
    &-cancel {
      background-color: $white;
      max-width: 106px;
      &:hover {
        background-color: $white;
      }
    }
    &-next {
      background-color: $btn-bg-additional;
      max-width: calc(100% - 136px);
      font-size: 17px;
    }
    &-xxl {
      font-weight: 500;
      max-width: 326px;
      font-size: 18px;
    }
    &-logIn &-logOut {
      background-color: transparent;
      color: $main-color;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.editButtons {
  display: flex;
  grid-gap: 15px;
  &-text {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: $white;
    svg path {
      fill: $white;
      stroke: $white;
    }
  }
  @media (max-width: $breakpoint-sm) {
    &-text {
      color: $main-color;
      grid-gap: 5px;
      svg {
        height: 18px;
        path {
          stroke: $main-color;
        }
      }
    }
  }
}
