.past-transactions {
  &-search{
    padding-left: 20px;
    padding-right: 20px;
    //transition: all 2s ease-out;
  }
  .search-block{
    transition: 2s;
    margin-bottom: $medium-margin;
  }
  &--main{
    .page-header{
      padding-left: 20px;
      padding-right: 20px;
      //transition: all 2s ease-out;
    }
  }
  &__additional-text{
    margin-bottom: $main-margin;
    color: $white;
    padding: 10px 10px 0;
    text-align: justify;
  }
  &__additional-block{
    display: flex;
    align-items: center;
    gap: 8px;
    font: 400 16px/19px "Roboto";
    color: $text-color;
    // margin-top: 24px;
    padding-left: 10px;
    padding-right: 10px;
    p{
      font: 400 14px/19px $primary-font;
      color: $white;
      padding: 6px 0;
    }
  }
  &__location{
    display: block;
    font: 700 18px/20px $primary-font;
    color: $white;
    margin-top: $medium-margin;
    margin-bottom: $large-margin;
    text-decoration: underline;
    text-align: end;
    &:hover{
      cursor: pointer;
    }
    svg{
      margin-left: $main-margin;
      path{
        stroke:$white; 
      }
    }
  }
  &__checkbox{
    font: 700 14px/16px $primary-font;
    color: $white;
    margin-bottom: $medium-margin;
    margin-left: $medium-margin;
    svg{
      margin-right: $medium-margin;
      path{
        stroke: $white;
      }
    }
  }
  @media (min-width: $breakpoint-sm + 1){
    &--main{
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;
      .page-header{
        width: 50%;
      }
      .past-transactions-search{
        width: 50%;
        padding-right: 0;   
        .search-block{
          margin-left: auto;
        }
      }
      .table{
        width: 100%;
        font-size: 15px;
      }
    }
    &__additional-text{
      margin-bottom: 26px;
    }
    &__info{
      background: $input-main-bg;
      border-radius: $border-radius-main;
      margin-bottom: $large-margin;
      padding: 10px 15px;
      .checkbox-wrapper{
        margin-top: 0;
      }
    }
    @supports (-webkit-touch-callout: none) {
      min-height: calc(100vh - 125px);
    }
  }
  @media (max-width: $breakpoint-sm){
    display: flex;
    flex-direction: column;
    // height: 100%;
    height: calc(100dvh - 130px);
    padding-bottom: 10px;
    &--main{
      .page-header{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &__info{
      margin-bottom: $medium-margin;
      // .checkbox-wrapper{
      //   justify-content: flex-start;
      //   padding-left: 16px;
      //   margin-top: 6px;
      //   font-size: 14px;
      //   label{
      //     color: $main-color;
      //   }
      // }
    }
    &__additional-text{
      font: 500 12px/18px $primary-font;
      color: $text-color;
      margin-bottom: $main-margin;
      flex: 1;
    }
    &__additional-block{
      align-items: flex-start;
      // margin-left: $main-margin;
      svg{
        margin-top:$main-margin;
      }
      p{
        color:  $text-color;
      }
    }
    &__checkbox{
      color: $text-color;
      svg path{
        stroke: $text-color;
      }
    }
    &__location{
      color: $text-color;
      text-align: end;
      font-size: 14px;
      line-height: 16px;  
      margin-top: 0;
      svg{
        width: 9px;
        height: 16px;
        path{
          stroke: $text-color;
        }
      }
    }
    &-search{
      padding-left: 15px;
      padding-right: 15px;
    }
    &--search{
      .table{
        min-height: calc(100vh - 401px);
        &-body{
          max-height: calc(100vh - 460px);
        }
      }
      @supports (-webkit-touch-callout: none) {
        .table{
          min-height: calc(100vh - 350px);
        }
      }
    }
    @supports (-webkit-touch-callout: none) {
      .table{
        min-height: calc(100vh - 305px);
      }
    }
  }
}

.status {
  @include d-flex($justify-content: unset);
  grid-gap: 5px;
  &-default {
    color: $main-color;
  }
  &-success {
    color: $green400;
  }
  @media (max-width: $breakpoint-xs) {
    justify-content: center;
  }
}

.booked {
  span {
    display: block;
  }
  @media (max-width: $breakpoint-xs) {
    text-align: center;
  }
}
