.modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
  &-window {
    background-color: $white;
    border-radius: $border-radius-main;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
    color: $main-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    min-width: 450px;
    width: calc(100% - 60px);
    max-height: 90vh;
    overflow: auto;
    padding: 22px 24px 24px;
    &.tx-details {
      min-width: 350px;
      width: 350px;
    }
  }
  &-share {
    background-color: $white;
    border-top-left-radius: $border-radius-main;
    border-top-right-radius: $border-radius-main;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
    color: $main-color;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 450px;
    min-width: 300px;
    max-height: 90vh;
    overflow: auto;
    padding: 22px 24px 24px;
  }

  &-header {
    @include d-flex(flex-start);
    position: relative;
    margin-bottom: $large-margin;
    h2 {
      @include d-flex(flex-start, flex-start);
      font: 500 18px/21px $primary-font;
      color: $text-color;
      padding-left: 6px;
      flex: 1;
      svg {
        margin-right: 6px;
      }
      &:not(.t-center) {
        @include d-flex(flex-start);
      }
    }
    &--big {
      h2 {
        font: 500 1.75rem/1.2 $primary-font;
        svg {
          height: 33px;
          width: 33px;
        }
      }
    }
  }
  &-close {
    svg {
      circle {
        fill: transparent;
      }
      path {
        fill: $main-color;
      }
    }
  }
  &-body {
    &-title {
      margin-bottom: 30px;
      font-size: 18px;
    }
    a {
      color: $text-color;
      text-decoration: underline;
    }
    h2 {
      color: $text-color;
      margin-bottom: 5px;
    }
    h5 {
      color: $text-color;
      font-size: 18px;
      max-width: 80%;
      margin: 40px auto 40px;
    }
    h4 {
      color: $main-color;
      font-size: 18px;
      max-width: 80%;
      margin: 40px auto 40px;
    }
    .btn-wrapper-method {
      .btn-method-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        margin-bottom: 5px;
        // border: 1px red solid;
        border-radius: 10px;
        border: 2px transparent solid;
        &.active-btn {
          border: 2px $main-color solid;
        }
        &:hover {
          cursor: pointer;
          background-color: $input-main-bg;
        }
        span {
          &:first-child {
            font-weight: 700;
            margin-bottom: 5px;
          }
          &:last-child {
            padding: 0 7px;
          }
        }
      }
    }
    .btn-wrapper {
      margin-top: 20px;
      &__delete-card {
        button:first-child {
          max-width: 160px;
          width: 100%;
        }
        button:last-child {
          max-width: calc(100% - 180px);
          width: 100%;
        }
      }
    }
  }
  &-content {
    margin-bottom: $medium-margin;
    &__row {
      @include d-flex(flex-start);
      margin-bottom: $main-margin;
      &.total {
        & > div {
          font-weight: 600;
        }
      }
    }
    &__name,
    &__value {
      font: 400 14px/16px $primary-font;
    }
    &__value {
      font-weight: 600;
      padding-left: 4px;
    }
  }
  &-container {
    margin: 0 auto;
  }
  @media (max-width: $breakpoint-sm) {
    &-body {
      padding: 5px 0px 2px;
      h5 {
        max-width: 90%;
        margin: 20px auto 40px;
      }
      h4 {
        &.mt-20 {
          margin-top: 20px;
        }
      }
    }
    &-header {
      &--big {
        h2 {
          font-size: 1.35rem;
          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
    &-window {
      min-width: 300px;
    }
    &-header {
      margin-bottom: $medium-margin;
    }
  }
}

.modal-window {
  //recipient details
  &-details {
    max-width: 810px;
    width: 90%;
    .modal-body {
      padding-bottom: 40px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      header {
        padding-right: 20px;
      }
      &-title {
        color: $grey500;
        margin-bottom: 20px;
        font-size: 18px;
      }
      .grid {
        margin-bottom: 30px;
      }
      label,
      input,
      .PhoneInputCountry,
      select {
        font-size: 20px;
      }
      input,
      .PhoneInputCountry,
      select {
        height: 35px;
      }
    }
    .btn-wrapper {
      margin-top: 30px;
    }
  }
  @media (min-width: $breakpoint-xs + 1) {
    &-details {
      .modal-body {
        .grid {
          & > div {
            max-width: 290px;
          }
        }
      }
    }
  }
  @media (max-width: $breakpoint-xs) {
    .btn-wrapper .btn {
      font-size: 16px;
      padding: 10px;
    }
  }
}

.modal-early-access {
  background-color: $white;
  border-radius: $border-radius-main;
  color: $text-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 404px;
  width: 100%;
  max-height: 305px;
  overflow: auto;
  padding: 16px 24px 24px;
  box-shadow: 0 0 5px 7px rgba(0, 0, 0, 0.3);
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
  &-header {
    @include d-flex(flex-end);
    position: relative;
    margin-bottom: 9px;
    button {
      svg {
        path {
          stroke: $text-color;
        }
      }
    }
  }
  &-body form {
    h3 {
      font: 600 28px $primary-font;
      color: $text-color;
      padding-left: 6px;
      margin-bottom: 20px;
    }
    button {
      width: 100% !important;
    }
  }
  @media (max-width: $breakpoint-xs) {
    max-width: calc(100% - 40px);
    &-body {
      form h3 {
        font-size: 24px;
      }
    }
  }
}
