.pending-circle {
	width: 85px;
  height: 85px;
  border: 4px solid $main-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  .loader {
    width: 50px;
    height: 50px;
    border: 4px solid $main-color;
    border-radius: 50%;
    animation: spin 5s linear infinite;
    position: relative;
    
    &:before,
    &:after{
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-top: 2px solid $white;
      border-right: 2px solid $white;
      position: absolute;
      top: 5px;
      left: -4px;
      box-shadow: 2px -2px 0 2px $white;
    }
    
    &:after {
      top: 29px;
      left: 35px;
      border: none;
      border-bottom: 2px solid $white;
      border-left: 2px solid $white;
      box-shadow: -2px 2px 0 2px $white;
    }
  }
    
  @media (min-width: $breakpoint-sm + 1){
      border-color: $white;
      .loader{
        border-color: $white;
      }
  }
  @media (max-width: $breakpoint-sm){
    .loader{
      &:before{
        box-shadow: 2px -2px 0 2px $main-color;
        border-width: 1px;
        border-color: $main-color;
      }
      &:after{
        box-shadow: -2px 2px 0 2px $main-color;
        border-width: 1px;
        border-color: $main-color;
      }
    }
  }
   
  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
}

