.cash-block-wrapper {
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: $breakpoint-sm + 1) {
        padding-top: 50px;
        padding-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
        margin-top: 20px;
    }
}

.cash-block {
    .icon-arrow {
        height: fit-content;
        align-self: flex-end;
        text-align: center;
    }
    @media (max-width: $breakpoint-sm) {
        .icon-arrow {
            margin-top: 20px;
            margin-bottom: 20px;
            svg {
                transform: rotate(90deg);
            };
        }
    }
    @media (min-width: $breakpoint-sm + 1) {
        @include d-flex($align-items: unset);
        grid-gap: 30px;
    }
}

.currency-input {
    position: relative;
    .currency {
        position: absolute;
        right: 0;
        bottom: 12px;
        @include d-flex;
        grid-gap: 5px;
        strong {
            font-size: 18px;
            font-weight: 500;
            color: #000;
        }
    }
    input {
        padding-right: 100px;
        height: auto;
    }    
    @media (max-width: $breakpoint-sm) {
        max-width: 270px;
        margin: 0 auto 25px;
        label {
            text-align: center;
        }
        .form-input-error {
            bottom: -45px;
        }
    }
}

.rate {
    text-align: center;
    margin-top: 45px;
    h4 {
        font-size: 16px;        
        font-weight: 500;
    }
    p {
        font-weight: 500;
    }
    @media (max-width: $breakpoint-sm) {
        margin-top: 55px;
    }
}