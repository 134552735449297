.profile {
  &-header {
    @include d-flex;
    grid-gap: 20px;
    color: #fff;
    h1 {
      font-size: 24px;
      font-weight: 400;
      max-width: 1100px;
      width: 100%;
      border-bottom: 1px solid #daeaff;
      padding-bottom: 10px;
    }
    .close {
      @include d-flex;
      grid-gap: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  &-form {
    position: relative;
    &-row {
      @include d-flex($justify-content: unset, $align-items: flex-end);
      grid-gap: 45px;
      & > div {
        position: relative;
        &:first-child {
          max-width: 360px;
          width: 100%;
          label {
            padding-left: 12px;
          }
        }
      }
    }
    & > div {
      position: relative;
    }
    .timer {
      padding-left: 12px;
      text-align: left;
    }
    .form-input {
      &-success.t-white {
        color: #fff;
        position: static;
        padding-left: 12px;
      }
      &-success,
      &-error {
        left: 12px;
      }
    }
    .verified {
      position: absolute;
      bottom: -25px;
      left: 0;
    }
    .btn {
      padding: 9px 15px;
      height: 44px;
    }
  }

  @media (max-width: $breakpoint-sm) {
    &-page {
      background: $body-bg;
      padding-top: 40px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      h1 {
        margin-bottom: 0;
        border-bottom: none;
      }
      .close {
        span {
          font-size: 0;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    &-form {
      &-row {
        grid-gap: 30px;
        div:first-child {
          max-width: 250px;
          width: 100%;
        }
        input {
          font-size: 16px;
          padding-left: 16px !important;
        }
      }
      .timer {
        color: $white;
      }
    }

    // &-header{
    //     color: $main-color;
    //     h1{
    //         border-color: $main-color;
    //     }
    // }
  }
  @media (max-width: $breakpoint-lg) and (max-height: $breakpoint-xs) {
    &-page {
      height: auto;
      padding-bottom: 15px;
    }
  }
}

.preferences {
  & > p {
    font-size: 10px;
  }
  .toggler {
    max-width: 350px;
    margin-bottom: 10px;
  }
}
.limits {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  
  & > div {
    display: flex;
    p {
      width: 65%;
      &:first-child {
        width: 35%
      }
    }
  }
}

.tab {
  &-links {
    @include d-flex($justify-content: unset);
    grid-gap: 20px;
    max-width: 1100px;
    padding-top: 10px;
    padding-left: 20px;
    & > * {
      font-size: 18px;
      color: #fff;
      &.active {
        font-weight: 800;
        text-decoration: underline;
      }
    }
  }
  &-content {
    max-width: 1100px;
    padding-top: 30px;
    padding-left: 20px;
    color: #fff;
    label {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      margin-bottom: 0;
    }

    input {
      //color: #fff;
      //border-bottom: 2px solid #fff;
      //padding-left: 10px;
      //padding-right: 0;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
    }
    .verified {
      position: static;
      margin-top: 16px;
      margin-left: 12px;
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    &-links,
    &-content {
      padding-left: 60px;
    }
  }
}

.success-page {
  @include d-flex(center, center);
  flex-direction: column;
  grid-gap: 30px;
  min-height: 231px;
  flex: 1 1;
  .confirm-email {
    @include d-flex($justify-content: center);
    flex-direction: column;
    span {
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
      color: $white;
      text-align: center;
    }
  }
  h1 {
    color: $white;
    @include d-flex(center);
    grid-gap: 10px;
    margin-bottom: 45px;
  }
  .btn-wrapper {
    width: 300px;
    button {
      margin: 0 auto;
    }
  }
  @media (max-width: $breakpoint-sm) {
    h1 {
      color: $main-color;
      text-align: center;
    }
    .confirm-email {
      p {
        color: $main-color;
      }
    }
  }
}
