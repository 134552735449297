.table {
  background-color: $table-body;
  border-top-left-radius: $border-radius-main;
  border-top-right-radius: $border-radius-main;
  overflow: hidden;
  height: 100%;
  min-height: calc(100vh - 357px);
  display: flex;
  flex-direction: column;
  //padding-bottom: 20px;

  .transaction-item {
    // border: 1px solid red;
  }
  &-header {
    @include d-flex();
    border: 1px solid $table-header;
    border-top-left-radius: $border-radius-main;
    border-top-right-radius: $border-radius-main;
    padding: 5px 9px;
    padding-right: 18px;
    &__item {
      color: $main-color;
      padding: 5px 0;
      font-weight: 700;
      // border: 1px solid;
      &.show-more {
        flex: 1;
        text-align: end;
      }
      &.btn {
        color: #fff;
      }
      &:nth-child(6) {
        // padding-left: 6px;
      }
    }
  }
  &-body {
    max-height: calc(100vh - 416px);
    position: relative;
    flex: 1;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
  &-row {
    @include d-flex(flex-start);
    padding: 0 9px;
    min-height: 47px;
   
    &:nth-child(odd) {
      background-color: $table-row;
    }
    &:hover {
      background-color: #cce2ff;
      cursor: pointer;
      // .show-more{display: flex;}
    }

    &.sanctioned-beneficiary {
      background-color: lightgrey;

      &:hover {
        cursor: not-allowed;
      }
      .table-item {
        
        color: #b4b4b4;
        &.beneficiary-type svg path {
          fill: #b4b4b4;;
        }
      }
    }
  }
  &-item {
    padding: 5px 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:not(.small) {
      color: $main-color;
    }
    &.underline {
      text-decoration: underline;
      display: flex;
    }
    &.bold {
      font-weight: 700;
    }
    &.medium {
      font-weight: 500;
    }
    &.small {
      font-size: 12px;
      svg {
        width: 14px;
        height: 10px;
        margin-right: 4px;
      }
    }
    svg {
      margin-right: 4px;
    }
    &.row {
      @include d-flex(flex-start, center);
    }
    &.show-more {
      @include d-flex(flex-end);
      flex: 1;
      div {
        flex: 1;
        text-align: start;
      }
      .rc-tooltip-inner {
        border-radius: 0.5px;
      }
      button {
        font-size: 14px;
        line-height: 16px;
        max-width: 94px;
        height: 32px;
        padding: 2px 5px;
      }
    }
    .name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 34px;
      // display: inline-block;
    }
  }
  @media (max-width: $breakpoint-lg) and (max-height: $breakpoint-xs) {
    min-height: auto;
    &-body {
      max-height: initial;
      min-height: 125px;
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    &-header {
      background-color: #e6e6e6;
      padding-top: 10px;
      padding-bottom: 10px;
      // padding-right: 18px;
    }
    &-item {
      .name {
        line-height: 46px;
        height: 46px;
      }
    }
  }
  @media (max-width: $breakpoint-sm) {
    // min-height: calc(100dvh - 340px);
    max-height: calc(100dvh - 240px);
    // min-height: calc(100vh - auto);
    padding-bottom: 20px;
    flex: 1;
    &-header {
      min-height: 43px;
      justify-content: flex-start;
      .transaction-item:nth-child(5) {
        width: calc(15% + 20px);
        min-width: 65px;
      }
      &__item {
        font-size: 12px;
        &:nth-child(1) {
          padding-right: 9px;
        }
        &:nth-child(6) {
          // padding-left: 6px;
        }
      }
    }
    &-body {
      max-height: calc(100dvh - 300px);
    }
    &-row {
      min-height: 43px;
      &:nth-child(even) .show-more circle {
        fill: $table-row;
      }
    }
    &-item {
      font-size: 12px;
      &.show-more {
        display: block;
        min-width: 32px;
        //min-width: 38px;
        font-size: 0;
        text-align: end;
        justify-content: flex-end;
        order: 7;
        padding: 5px 3px;
        button {
          padding: 0;
        }
      }
    }
  }
  // @media (max-width: $breakpoint-xs){
  //   &-header{
  //     //padding-right: 19px;
  //     // padding-right: 13px;
  //   }
  // }
}

.transaction-item {
  padding: 0 6px;
  //border: 1px solid red;
  &:nth-child(1) {
    min-width: 100px;
  }
  &:nth-child(2) {
    width: 17%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 9%;
  }
  &:nth-child(5) {
    width: 9%;
  }
  &:nth-child(6) {
    width: 12%;
    min-width: 120px;
  }
  &:nth-child(7) {
    width: 14%;
  }
  &:nth-child(8) {
    flex: 1;
    text-align: end;
  }
  @media (max-width: $breakpoint-lg) {
    padding: 0 4px;
    &:nth-child(4) {
      display: none;
    }
    &:nth-child(7) {
      font-size: 0;
      width: 20px;
    }
  }
  @media (max-width: $breakpoint-sm) {
    padding: 0 2px;
    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
    &:nth-child(1) {
      order: 0;
      min-width: 60px;
    }
    &:nth-child(2) {
      order: 1;
      // width: 30%;
      width: 25%;
      min-width: 95px;
    }
    &:nth-child(5) {
      order: 2;
      width: 15%;
      display: flex;
      justify-content: space-between;
      min-width: 45px;
      &.table-header__item {
        width: calc(15% + 13px);
        // width: calc(15% + 7px);
      }
    }
    &:nth-child(6) {
      order: 4;
      min-width: 93px;
    }
    &:nth-child(7) {
      order: 3;
      font-size: 0;
      width: 13px;
      min-width: 13px;
      svg {
        margin-right: 0;
      }
      &.table-header__item {
        display: none;
      }
    }
    &:nth-child(8) {
      order: 5;
      min-width: 32px;
      position: relative;
      button {
        display: none;
      }
      div {
        position: absolute;
        right: -8px;
        top: -10px;
        padding-right: 13px;
      }
    }
  }
}

.beneficiary-item {
  padding: 0 6px;
  &:nth-child(1) {
    width: 27%;
  }
  &:nth-child(2) {
    width: 100px;
  }
  &:nth-child(3) {
    width: 15%;
    min-width: 150px;
  }
  &:nth-child(4) {
    width: 13%;
  }
  &:nth-child(5) {
    flex: 1;
    text-align: end;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 0 2px;
    &:nth-child(1) {
      width: 33%;
    }
    &:nth-child(2) {
      width: 64px;
    }
    &:nth-child(3) {
      min-width: 100px;
    }
    &:nth-child(4) {
      width: 25px;
      font-size: 0;
    }
    &:nth-child(5) {
      div {
        //padding-right: 13px;
      }
    }
  }
}
