.failed-payment-section {
  @include d-flex(center, center);
  flex-direction: column;
  gap: 30px;
  margin-bottom: 16px;
  color: $error-color;
  height: 280px;

  p {
    font: 500 18px/28px $primary-font;
    color: $main-color;
    max-width: 300px;
    text-align: center;
  }
}
