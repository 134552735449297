.rc-tooltip{
    .rc-tooltip{
        &-inner{
            border-color: $main-color;
            padding: 7px 2px;
            & > div {
                display: flex;
                flex-direction: column;
                min-width: 100px;
            }
            button{
                color: $main-color;
                font-weight: 700;
                line-height: 26px;
                font-size: 14px;
                padding: 4px;
                max-width: 100%;
                &:hover{
                    background-color: #EFF6FF;
                }
            }
            @media (max-width: $breakpoint-xs){
                text-align: center;
            }
        }
    }
}