.doc-check-page {
  .additional-text {
    font-size: 18px;
    color: $white;
    text-align: justify;
    @media (max-width: $breakpoint-sm) {
      font-size: 14px;
      color: $main-color;
    }
  }
}
