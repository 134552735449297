.page-header {
    @include d-flex(space-between, flex-start);
    text-align: center;
    color: $white;
    margin-bottom: $large-margin;
    h1{
        flex: 1;
        text-align: start;
        &.center{
            text-align: center;
        }
    }
    &--between{
        @include d-flex;
        
    }
    &__title-btn{ 
        margin-left: 20px;  
    }
    &__title-icon{
        display: flex;
        position: relative;
        //margin-bottom: 10px;
        div{
            line-height: 25px;
            margin-right: 5px;
        }
    }
    .pl{
        padding-left: 20px
    }
    .pr{
        padding-right: 20px
    }
    @media (min-width: $breakpoint-sm + 1){
        &__title-btn{
            line-height: 46px;
        }
    }
    @media (max-width: $breakpoint-sm){
        margin-bottom: 14px;
        color: $main-color;
        
        &__title-icon{
            flex-direction: row;
        }
        &--between{
            //flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
        &__title-btn{
            margin-left: 0;
        }
        .pl{
            padding-left: 15px
        }
        .pr{
            padding-right: 15px
        }
      }
}