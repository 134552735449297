/*--------- reset / normalize ----------*/
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
  box-sizing: border-box;
}

body {
  position: relative;
  min-height: 100%;
  min-width: 320px;
}
article,    aside,   details,
figcaption, figure,  footer,
header,     main,    menu,
nav,        section, summary {
  display: block;
}
audio,    canvas,
progress, video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
abbr[title] { border-bottom: 1px dotted; }
b,
strong {
  font-weight: bold;
}
mark {
  background: #ff0;
  color: #000;
}
small { font-size: 80%; }
sub,
sup {
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  position: relative;
}
sup { top: -0.5em; }
sub { bottom: -0.25em; }
img {
  border: 0;
  display: block;
  height: auto;
  width: 100%;
}
svg:not(:root) {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  //transition: .4s;
}
pre { overflow: auto; }
code, kbd,
pre,  samp {
  font-family: monospace, monospace;
  font-size: 16px;
}
fieldset,
legend { border: 0; }
textarea {
  overflow: auto;
  resize: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
h1, h2, h3, h4, h5, h6 {
  clear: both;
}
dfn, cite, em, i {
  font-style: italic;
}
ul, ol { list-style: none; }
button { cursor: pointer; }
button[disabled],
input[disabled] {
  cursor: default;
}
button,
input,
textarea {
  border: none;
  outline: none;
  background: transparent;
  box-shadow: none;
  transition: 0.4s;
  font: inherit;
  margin: 0;
}
a:visited, a:hover, a:focus, a:active,
button:hover, button:active, button:focus,
input:hover, input:active, input:focus,
textarea:hover, textarea:active, textarea:focus {
  outline: none;
  box-shadow: none;
}
*:focus {
  outline: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
a {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: 0.4s;
}