.beneficiaries {
  &-search,
  &--main .page-header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .search-block {
    transition: 2s;
    margin-bottom: $medium-margin;
  }
  .btn-svg svg {
    top: 10px;
  }
  @media (max-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
    // height: 100%;
    height: calc(100dvh - 130px);
    padding-bottom: 10px;
    @supports (-webkit-touch-callout: none) {
      .table {
        min-height: calc(100vh - 305px);
      }
    }
    &-search,
    &--search .calculation-block,
    &--main .page-header {
      padding-left: 15px;
      padding-right: 15px;
    }
    &--search {
      .table {
        min-height: calc(100vh - 405px);
        &-body {
          max-height: calc(100vh - 460px);
        }
      }
      @supports (-webkit-touch-callout: none) {
        .table {
          min-height: calc(100vh - 370px);
          height: initial;
          flex: initial;
          &-body {
            height: initial;
          }
        }
        .show-mobile.t-center {
          flex: 1;
          @include d-flex(center, flex-end);
        }
      }
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    &--main {
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;
      .page-header {
        width: 50%;
      }
      .beneficiaries-search {
        width: 50%;
        padding-right: 0;
        .search-block {
          margin-left: auto;
        }
      }
      .table {
        width: 100%;
      }
    }
    &--search {
      .page-header {
        width: 100%;
      }
      .beneficiaries-search {
        width: calc(100% - 505px);
      }
      .table {
        min-height: calc(100vh - 401px);
        &-body {
          max-height: calc(100vh - 460px);
        }
      }
    }
    @supports (-webkit-touch-callout: none) {
      min-height: calc(100vh - 365px);
    }
  }
}

// beneficiary-details-page
.beneficiary-details-page {
  h2 {
    margin-bottom: $main-margin;
  }
  form {
    margin-bottom: 36px;
  }
  .details-section {
    margin-bottom: $medium-margin;
    &__title {
      @include d-flex(space-between, flex-start);
      position: relative;
      padding-right: 100px;
      button {
        position: absolute;
        right: 0;
        height: 34px;
      }
    }
  }
  .error-message {
    @include d-flex(unset, center);
    gap: 8px;
    font: 400 16px/19px $primary-font;
    color: $danger;
    margin-bottom: $large-margin;
    padding: 0 5px 0 10px;
    svg {
      min-width: 16px;
      & path {
        fill: $danger;
      }
    }
  }

  .warning-message {
    color: $warning-color;
    padding: 0 5px 0 10px;
  }

  .withdrawal-account-number-info {
    padding: 0 16px;
    margin-bottom: 16px;
    color: $white;
    font-size: 14px;
    &-raw {
      display: flex;
      div {
        margin-right: 5px;
        &:last-child {
          flex: 1;
        }
      }
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    h2 {
      font-size: 18px;
      margin: $main-margin 0 $main-margin $medium-margin;
    }
    .details-section {
      margin-bottom: $large-margin;
    }
  }
  @media (max-width: $breakpoint-sm) {
    @supports (-webkit-touch-callout: none) {
      min-height: calc(100vh - 250px);
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 18px;
    }
    form {
      flex: 1;
    }
    .details-section {
      &__title {
        button {
          height: 21px;
          span {
            max-width: 37px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .withdrawal-account-number-info {
      color: $text-color;
    }
    .warning-message {
      color: $warning-color-mobile;
    }
  }
}
