input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::placeholder,
::-webkit-input-placeholder {
  color: $input-ph;
  opacity: 1;
}
.error::placeholder,
.error::-webkit-input-placeholder {
  color: $danger;
}
input:disabled::placeholder,
input:disabled::-webkit-input-placeholder {
  color: $disabled;
}
@media (max-width: $breakpoint-sm) {
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 18px;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px white;
  -webkit-text-fill-color: $main-color;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='date'] {
  position: relative;
  cursor: pointer;
  height: 44px;
  // &:before{
  //   position: absolute;
  //   display: inline-block;
  //   content: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 3H5C2.79086 3 1 4.79086 1 7V17C1 19.2091 2.79086 21 5 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3Z' stroke='%233171D8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 1V5M15 1V5M1 9H21' stroke='%233171D8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  //   width: 20px;
  //   height: 20px;
  //   right: 14px;
  //   bottom: 14px;
  // }
  // &:disabled:before{
  //   content: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 3H5C2.79086 3 1 4.79086 1 7V17C1 19.2091 2.79086 21 5 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3Z' stroke='%239D9D9D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 1V5M15 1V5M1 9H21' stroke='%239D9D9D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  // }
}

input::-webkit-calendar-picker-indicator {
  position: absolute;
  cursor: pointer;
  background: transparent;
  bottom: 0;
  color: transparent;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}

.form-input-label,
.form-select-label {
  color: $white;
  padding-left: 7px;
  font-size: 15px;
  @media (max-width: $breakpoint-sm) {
    color: $main-color;
  }
}

.form-input-block {
  position: relative;

  .form-date-input {
    width: 0;
    padding: 0;
    background: transparent;

    &.show-date-input {
      position: absolute;
      right: 0;
      opacity: 0;
      width: 50px;
    }

    &-icon {
      position: absolute;
      padding: 9px 16px 12px;
      top: 0;
      right: 0;
      &:hover {
        cursor: pointer;
      }
    }
    &-ui {
      background-color: $input-main-bg;
      width: 100%;
      font-size: 18px;
      padding: 11px 16px;
      border-radius: $border-radius-main;
      &:focus {
        background-color: #fafcff;
      }
      &.error {
        background-color: $error-bg;
        color: $danger;
      }
    }
  }

  &.error {
    background-color: $error-bg;
    color: $input-ph;
  }

  &.disabled {
    color: $disabled;
    .form-date-input-icon {
      svg path {
        stroke: $disabled;
      }
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.form-date-input {
  position: relative;
  &-value,
  &-placeholder {
    background-color: $input-main-bg;
    width: 100%;
    font-size: 18px;
    padding: 11px 16px;
    cursor: pointer;
    border-radius: $border-radius-main;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 3H5C2.79086 3 1 4.79086 1 7V17C1 19.2091 2.79086 21 5 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3Z' stroke='%233171D8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 1V5M15 1V5M1 9H21' stroke='%233171D8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 97%;
    &.error {
      background-color: $error-bg;
      color: $input-ph;
    }
  }
  &-value {
    color: $main-color;
  }
  &-placeholder {
    color: $input-ph;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    font-size: 0;
    text-indent: -9999px;
    &:focus {
      background-color: transparent;
    }
    &:focus + div {
      background-color: #fafcff;
    }
    &:focus + div.error {
      background-color: $error-bg;
    }
    &:disabled + div {
      color: $disabled;
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 3H5C2.79086 3 1 4.79086 1 7V17C1 19.2091 2.79086 21 5 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3Z' stroke='%239D9D9D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 1V5M15 1V5M1 9H21' stroke='%239D9D9D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
}

input {
  background-color: $input-main-bg;
  color: $main-color;
  width: 100%;
  font-size: 18px;
  padding: 11px 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  &:focus {
    background: #fafcff;
  }
  &:disabled {
    color: $disabled;
    cursor: not-allowed;
    opacity: 1;
  }
  &:not(.amount) {
    border-radius: $border-radius-main;
    &:hover {
      //border-color: $main-color;
    }
  }
  &.error {
    background-color: $error-bg;
    color: $danger;
  }
  &.amount {
    font: 500 24px/28px $primary-font;
    background-color: transparent;
    padding: 0;
    //max-width: 92px;
    height: 28px;
    width: 100%;
    text-align: end;
    &:hover {
      border-color: transparent;
    }
  }
  @media (max-width: $breakpoint-sm) {
    &.search {
      font: 400 14px/16px $primary-font;
      padding: 6px 30px 6px 12px;
      height: 44px;
    }
  }
}

select {
  background-color: $input-main-bg;
  border-radius: $border-radius-main;
  color: $main-color;
  padding: 11px 35px 11px 16px;
  font-size: 18px;
  width: 100%;
  border: none;
  cursor: pointer;
  height: 44px;
  /** for the dropdown indicator */
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5.01591 4.99996L9 1' stroke='%233171D8' stroke-width='1.68756' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.5em;
  &:focus {
    background-color: #fafcff;
  }
  &:disabled {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5.01591 4.99996L9 1' stroke='%239D9D9D' stroke-width='1.68756' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    color: $disabled;
    cursor: not-allowed;
    opacity: 1;
  }
  &.error {
    background-color: $error-bg;
    color: $danger !important;
  }
  option {
    font-size: 18px;
    &:disabled {
      color: $disabled;
    }
  }
  @media (max-width: $breakpoint-sm) {
    //font-size: 16px;
    option {
      font-size: 16px;
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    // font-size: 24px;
    // height: 50px;
    // padding: 10px 10px 2px;
    // -webkit-border-radius:0;
  }
}

textarea {
  background-color: $input-main-bg;
  border-radius: $border-radius-main;
  color: $main-color;
  padding: 20px 16px;
  font-size: 18px;
  &.error {
    background-color: $error-bg;
    color: $danger;
  }
}

/*   PhoneInput  */
.PhoneInput {
  @include d-flex(space-between, center);
  //max-width: 360px;
  width: 100%;
  .PhoneInputInput {
    max-width: calc(100% - 100px);
    width: 100%;
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    &[data-autocompleted] {
      background-color: transparent !important;
    }
  }
  .PhoneInputCountry {
    @include d-flex(space-between, center);
    font: 400 18px/21px $primary-font;
    background-color: $input-bg;
    border-radius: $border-radius-main;
    color: $main-color;
    grid-gap: 20px;
    padding: 10px 17px;
    min-width: 80px;
  }

  @media (max-width: $breakpoint-xs) {
    grid-gap: 0;
    .PhoneInputInput {
      width: 100%;
    }
  }
}

.mobile-number {
  margin-bottom: $large-margin;
  &.details {
    margin-bottom: $medium-margin;
  }
  &-input {
    @include d-flex(space-between, center);
    width: 100%;

    input {
      max-width: calc(100% - 110px);
      width: 100%;
      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
      &[data-autocompleted] {
        background-color: transparent !important;
      }
    }
    select {
      @include d-flex(space-between, center);
      font: 400 18px/21px $primary-font;
      padding: 10px 17px;
      min-width: 90px;
      width: auto;
    }
  }
  @media (max-width: $breakpoint-sm) {
    margin-bottom: $main-margin;
    &-input {
      input {
        max-width: calc(100% - 100px);
      }
    }
  }
}

.form-row {
  @include d-flex;
  position: relative;
}

.label-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.custom-phone {
  grid-gap: 0;
  .PhoneInputCountry {
    padding-right: 0;
  }
  @media (min-width: $breakpoint-sm + 1) {
    .PhoneInputCountry {
      min-width: 15px;
    }
  }
}

.form-input,
.select-form {
  position: relative;
  margin-bottom: $medium-margin;
  &-example {
    width: 100%;
    padding-left: 12px;
    color: $input-ph;
    font-size: 14px;
  }
  &-error {
    width: 100%;
    color: $danger;
    font-size: 12px;
    padding-left: 12px;
    a {
      color: $danger;
      text-decoration: underline;
    }
  }
  &-success {
    position: absolute;
    bottom: -25px;
    color: $green400;
  }
  &-additional-text {
    padding: 0 12px;
    color: $white;
  }
  @media (max-width: $breakpoint-sm) {
    margin-bottom: $main-margin + 4;

    &-additional-text {
      color: $text-color;
    }
  }
}

.checkbox-wrapper {
  @include d-flex($justify-content: center);
  margin-top: 20px;
  .form-input-error {
    width: 100%;
  }
  input {
    width: auto;
    display: none;
    cursor: pointer;
    &:checked + label {
      &:after {
        @include pseudo($top: 2px, $left: 9px);
        width: 6px;
        height: 14px;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  label {
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid;
      padding: 9px;
      position: relative;
      cursor: pointer;
      margin-right: 15px;
      border-radius: 4px;
      transition: all 0.2s ease;
    }
    &:after {
      border-style: solid;
    }
  }
  // &-green {
  //   input:checked + label:before {
  //     background-color: $main-color;
  //     border-color: $main-color;
  //   }
  //   label {
  //     &:before {
  //       border-color: $main-color;
  //     }
  //     &:after {
  //       border-color: $white;
  //     }
  //   }
  // }
  &-white {
    input:checked + label:before {
      background-color: $check-box;
      border-color: $check-box;
    }
    label {
      color: $white;

      &:before {
        border-color: $white;
      }
      &:after {
        border-color: $white;
      }
    }
  }
  &-blue {
    input:checked + label:before {
      background-color: $white;
      border-color: $main-color;
    }
    label {
      color: $main-color;
      &:before {
        border-color: $main-color;
      }
      &:after {
        border-color: $main-color;
      }
    }
  }
  &-terms {
    label {
      color: $main-color;
      font-weight: 700;
    }
    a {
      color: $main-color;
      font-weight: 700;
      margin-left: 5px;
    }
  }
  &:hover {
    a {
      text-decoration: underline;
    }
  }
  @media (max-width: $breakpoint-sm) {
    input {
      &:checked + label {
        &:after {
          top: 5px;
          left: 8px;
          width: 4px;
          height: 11px;
        }
      }
    }
    label {
      font-size: 12px;
      &:before {
        padding: 7px;
      }
    }
  }
}

.checkbox-icon {
  @include d-flex($justify-content: center);
  grid-gap: 10px;
  &-white {
    & > * {
      color: #fff;
    }
  }
  &-blue {
    & > * {
      color: $main-color;
    }
  }
  svg {
    min-width: 24px;
  }
  &-50 {
    width: calc(50% - 10px);
  }
  @media (max-width: $breakpoint-sm) {
    &-white {
      //margin-bottom: 5px;
      & > * {
        color: $main-color;
      }
      svg path {
        stroke: $main-color;
      }
    }
  }
}

.toggler {
  @include d-flex;
  p {
    font-size: 18px;
    color: #fff;
  }
  .checkbox-toggle {
    width: 50px;
    height: 30px;
    background-color: #fff;
    text-align: right;
    border-radius: 25px;
    input {
      display: none;
    }
    label {
      height: 30px;
      width: 100%;
      display: block;
      position: relative;
      padding: 3px;
      &:before {
        content: '';
        left: 3px;
        height: 24px;
        width: 24px;
        display: block;
        border-radius: 25px;
        position: absolute;
        background-color: $grey-color;
        transition: left 0.3s, background-color 0.3s;
      }
    }
    input:checked + label:before {
      left: calc(100% - 27px);
      background-color: $green200;
    }
  }
}

.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
  left: 7px;
}

.checkbox .checkbox__checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 22px;
  width: 22px;
  border: 2px solid #3171D8;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  transform: translateY(-50%);
}
.checkbox .checkbox__checkmark:after {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  border: solid #3171D8;
  width: 6px;
  height: 14px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.checkbox .checkbox__body {
  color: #3171d8;
  text-align: justify;
  font-weight: 700;
}
