

.user-info-block{
    background: $input-main-bg;
    border-radius: $border-radius-main;
    margin-bottom: $main-margin;
    padding: 10px 15px;
    &__title{
        font: 500 20px/1 $primary-font;
        color: $main-color;
        margin-bottom: 0;
    }
    &__flag{
        width: 28px
    }
    &__row{
        @include d-flex(space-between, flex-start);
        position: relative;
        span{
            font: 400 18px/37px $primary-font;
            color: $main-color;
            &:first-child{
                width: 30%;
            }
            &:last-child{
                width: 70%;
                text-align: end;
            }
        }
    }
    &__separate{
        background-color: $white;
        height: 0.5px;
        width: calc(100% + 30px);
        position: absolute;
        bottom: 1px;
        left: -15px;
        right: 15px;
        
    }
    &__title,
    &__flag{
           margin-bottom: $main-margin; 
    }
    @media (max-width: $breakpoint-sm){
        &.mb-main{
          margin-bottom: $main-margin;
        }
      }
    @media (min-width: $breakpoint-sm + 1){
        margin-bottom: $medium-margin;
        
    }
}
.user-info-select {
    flex: 1;
    margin-bottom: 36px;
    @media (max-width: $breakpoint-sm){
        @supports (-webkit-touch-callout: none) {
            min-height: calc(100vh - 506px);
        }
    }
}
.phone-block {
    width: 100%;
    padding-bottom: $main-margin;
    
    @media (min-width: $breakpoint-sm + 1){
        h4{
            margin-left: $medium-margin;
            margin-bottom: $medium-margin + 2;
        }
    }
    @media (max-width: $breakpoint-sm){
        flex: 1;
        height: 100%;
        h4{
            font-size: 18px;
            margin-bottom: $main-margin + 4;
            margin-left: $medium-margin;
        }
    }
}

.user-info-button{
    @media (max-width: $breakpoint-sm){
        @supports (-webkit-touch-callout: none){
            position: fixed;
            right: 16px;
            left: 16px;
            bottom: 80px;
        }
    }
}
.verification-section{
    @media (max-width: $breakpoint-sm){
        height: calc(100dvh - 130px);
        // flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        @supports (-webkit-touch-callout: none) {
            min-height: calc(100vh - 202px);
        }
    }
}