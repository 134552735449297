$primary-font:      "Roboto";

// $body-bg:         linear-gradient(180deg, #3C8BF6 4.69%, rgba(128, 182, 255, 0.85) 30.21%, #4E96F8 100%);
$body-bg:             linear-gradient(180deg, #221772 0%,#221772 41.67%,rgba(34, 23, 114, 0.71) 100%);
$body-bg-img:           url('../../img/bg.png');
$header-bg:         #FFF;

$main-color:        #3171D8;
// $text-color:       #140A5A;
// $main-color:       #221772;

$text-color:        #221772;
$white:             #ffffff;

$input-main-bg:     #EFF6FF;
$input-bg:          #F6F6F6;
$input-ph:          #7B7B7B;
$btn-bg:            #3A5AC2;
$btn-bg-additional: #221772;
$btn-bg-hover:      #556EBE;
$disabled:          #9D9D9D;


$table-header:        #E6E6E6;
$table-body:          #FFF;
$table-row:           #EFF6FF;

$border-color:        #E0E0E0;  

$error-bg:            #FBDADA;
$error-color:         #F10000;
$danger:              #EB5757;

$bange:               #F80000;
$check-box:           #46BEFA;

$grey-color:          #707070;
$warning-color:       #FDFE02;
$warning-color-mobile:#FFC011;

//border
$border-radius-main:      10px;
$border-radius-secondary: 26px;

// margin
$main-margin:   8px;
$medium-margin: 16px;
$large-margin:  24px;


// breakpoints
$breakpoint-xs:         576px;
$breakpoint-sm:         768px;
$breakpoint-lg:         992px;
$breakpoint-xl:         1200px;
$breakpoint-xxl:        1400px;


 
$grey500:             #707070;
$green200:            #28ffb4;
$green400:            #24E2A0;



