.price {
  &-title {
    font: 500 16px/18px $primary-font;
    color: $main-color;
    margin-bottom: $medium-margin;
  }
  &-info {
    background: $input-main-bg;
    border-radius: $border-radius-main;
    // margin-bottom: $large-margin;
    padding: 20px 15px 20px;
    a {
      color: $main-color;
      text-decoration: underline;
    }
    &__text {
      // padding-top: 10px;
      font-size: 18px;
      color: $main-color;
    }
    &__note {
      font: 400 14px/16px $primary-font;
      margin-top: 12px;
    }
    &__result {
      @include d-flex(center, center);
      flex-direction: column;
      height: 330px;
      svg {
        margin-bottom: 30px;
      }
      p {
        font: 500 18px/28px $primary-font;
        color: $main-color;
        max-width: 300px;
        text-align: center;
      }
    }
    &__payment-title {
      color: $main-color;
      font: 600 20px $primary-font;
      text-align: center;
      margin-top: 12px;
      margin-bottom: 22px;
    }
    .device-data-form {
      height: 140px;
    }
    @media (max-width: $breakpoint-sm) {
      &.mb-main {
        margin-bottom: $main-margin;
      }
      &-footer {
        flex: 1;
      }
      &__result {
        height: 300px;
        svg {
          margin-bottom: 20px;
          height: 100px;
        }
        p {
          font: 500 22px/34px $primary-font;
          max-width: 100%;
        }
      }
    }
  }
  &-row {
    @include d-flex(flex-start, flex-start);
    position: relative;
    margin-bottom: $medium-margin;
    max-width: 350px;
    &__name {
      font: 700 14px/24px $primary-font;
      color: $text-color;
      @include d-flex(flex-start, center);
      svg {
        margin-right: 6px;
      }
    }
    &__value {
      font: 700 14px/24px $primary-font;
      color: $main-color;
      flex: 1;
      padding: 0 3px;
    }
    &__copy {
      .copy-field svg {
        width: 17px;
        height: 17px;
        path {
          fill: $main-color;
        }
      }
    }
    @media (max-width: $breakpoint-sm) {
      margin-bottom: $main-margin;
      max-width: inherit;
      &__name,
      &__value {
        //font: 700 14px/18px $primary-font;
      }
    }
  }

  &-tabs {
    @include d-flex(flex-start);
    background: $white;
    border-radius: $border-radius-main;
    padding: 4px;
    width: max-content;
    margin: 0 auto 12px;
  }
  &-tab {
    font: 500 18px/21px $primary-font;
    color: $main-color;
    padding: 9px 21px;
    border-radius: 6px;
    cursor: pointer;
    &.active {
      background-color: $btn-bg-additional;
      color: $white;
    }
  }
}

.card-payment {
  position: relative;
  &__item {
    position: relative;
    @include d-flex(space-between);
    margin-bottom: 20px;
    cursor: pointer;
    &-disabled {
      position: absolute;
      left: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 40px);
      border-radius: 10px;
      background: rgba(217, 217, 217, 0.85);
      cursor: default;

      .icon-info {
        position: absolute;
        width: 24px;
        height: 24px;
        padding: 4px;
        top: calc(50% - 14px);
        right: 122px;
        cursor: pointer;
      }
    }
    &:last-child {
      .card-payment__item-disabled {
        height: calc(100% + 20px);
        top: 0;
      }

      padding-top: 20px;
      margin-bottom: 0;
    }
    & > svg {
      margin-right: $large-margin;
    }
    span {
      font: 600 1rem/1.2 $primary-font;
      display: block;
      text-align: left;
    }
  }
  &__icon-block {
    display: flex;
    grid-gap: 24px;
    min-width: 215px;
    align-items: center;
    div {
      align-self: flex-start;
    }
  }
  // &__btn {
  //   display: flex;
  //   align-items: center;
  //   grid-gap: 15px;

  //   border-radius: 10px;
  //   background-color: #3171d8;
  //   color: #ffffff;
  //   padding: 8px 17px;
  //   height: 34px;
  //   text-align: center;
  //   white-space: nowrap;
  //   @media (min-width: $breakpoint-sm + 1) {
  //     &:hover {
  //       background-color: $btn-bg-hover;
  //     }
  //   }
  //   span {
  //     font: 500 18px/22px 'Roboto';
  //   }
  //   svg {

  //     path {
  //       stroke: $white;
  //     }
  //   }
  // }
  &__icons {
    svg {
      margin-right: 6px;
    }
  }
  &__iframe {
    background: $white;
    margin-top: 10px;
    border-radius: $border-radius-main;
    padding: 0;
    max-height: 300px;
    min-height: 300px;
    width: 100%;
    border: none;
  }
  @media (max-width: $breakpoint-sm) {
    &__iframe {
      max-height: calc(100vh - 510px);
      min-height: 370px;
    }
  }
  @media (max-width: $breakpoint-xs) {
    &__icon-block {
      grid-gap: 15px;
      & > svg {
        width: 65px;
      }
    }
    &__item-disabled .icon-info {
      right: 110px;
    }
  }
}

.payment {
  &-percent {
    font-size: 14px;
    color: $main-color;
    @media (min-width: $breakpoint-sm + 1) {
      font-size: 18px;
    }
  }
  // &-amount {
  //   font-size: 18px;
  //   font-weight: bold;
  //   color: $main-color;
  // }
  &-btn-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-btn {
    display: flex;
    align-items: center;
    grid-gap: 15px;

    border-radius: 10px;
    background-color: #3171d8;
    color: #ffffff;
    padding: 8px 17px;
    height: 34px;
    text-align: center;
    white-space: nowrap;
    @media (min-width: $breakpoint-sm + 1) {
      &:hover {
        background-color: $btn-bg-hover;
      }
    }
    span {
      font: 500 18px/22px 'Roboto';
    }
    svg {
      path {
        stroke: $white;
      }
    }
  }
  @media (max-width: $breakpoint-xs) {
    &-btn {
      padding: 8px 10px;
    }
  }
}

.card-selection {
  min-height: 258px;
  &__title {
    font: 500 1rem/2 $primary-font;
    color: $main-color;
    margin-bottom: 12px;
    padding-left: 35px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      display: block;
      left: 14px;
      top: 44%;
      transition: left 0.1s;
      border-color: $main-color;
      border-style: solid;
      border-width: 0 0 2px 2px;
      height: 12px;
      width: 12px;
      margin-top: -5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  &__rows {
    height: 150px;
    overflow-y: auto;
    padding-right: 4px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
  &__row {
    @include d-flex(space-between);
    margin-bottom: $medium-margin;
    & > span {
      font-weight: 500;
      color: $error-color;
    }
  }
  &__item {
    @include d-flex(flex-start);
    background: #fff;
    color: #140a5a;
    border-radius: 10px;
    padding: 8px;
    width: 66%;
    svg {
      margin-right: $main-margin;
    }
    p {
      display: block;
      flex: 1;
      // width: 80px;
      white-space: nowrap; /* Запрещаем перенос строк */
      overflow: hidden; /* Обрезаем все, что не помещается в область */
      text-overflow: ellipsis;
    }
  }
  &__no-cards {
    text-align: center;
    font-size: 20px;
    margin-top: 25px;
    // margin-bottom: 110px;
  }
  &__action {
    max-width: 220px;
    margin: $main-margin auto 0;
  }
  &__select {
    font: 500 0.875rem/1.2 $primary-font;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
  }
  @media (max-width: $breakpoint-sm) {
    min-height: 254px;
    &__rows {
      height: 145px;
    }
    &__action {
      .btn-primary {
        width: 100% !important;
      }
    }
  }
  @media (max-width: $breakpoint-xs) {
    &__row {
      flex-wrap: wrap;
    }
    &__item {
      width: calc(100% - 131px);
      padding: 8px 6px;
      // margin-bottom: 8px;
    }
  }
}

.new-card-form {
  .columns {
    @include d-flex(flex-start, flex-start);
    gap: 12px;
  }
  .field {
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-bottom: $medium-margin;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    & > section {
      height: 44px;
      padding: 11px 16px;
      background-color: $white;
      border-radius: 10px;
      color: green;
      font-size: 12px;
      &.is-onfocus {
        // border-color: black;
      }
      &.is-empty {
        // border-color: orange;
      }
      &.is-invalid {
        background-color: $error-bg;
      }
      &.is-valid {
        // border-color: green;
      }
      .test {
        input {
          background-color: red;
        }
      }
    }
    .form-input {
      margin-bottom: 0;
      input:not(.error) {
        background-color: $white;
      }
    }
  }
  .checkbox-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    justify-content: flex-start;
    // label{
    //   font-weight: 400;
    //   &:before{
    //     padding: 7px;
    //     margin-right: 11px;
    //   }
    // }
    // input:checked + label:after{
    //   left: 7px;
    //   top: 4px;
    //   width: 4px;
    //   height: 12px;
    // }
  }
}
