.payment {
  &-summary {
    &-section {
      position: relative;
      min-height: 200px;
    }
    &-info__row {
      @include d-flex(space-between, flex-start);
      align-items: center;
      color: $main-color;
      span:not(.tx-rate):not(.tx-fee):not(.receive-method-label),
      .receive-method-btn-block {
        font: 400 18px/37px $primary-font;
        svg {
          width: 20px;
          height: 22px;
          padding-bottom: 2px;
        }
        &:first-child {
          width: 40%;
        }
        &:last-child {
          width: 60%;
          font-weight: 500;
          text-align: end;
        }
        @media (max-width: $breakpoint-sm) {
          font-size: 16px;
        }
      }
      .receive-method {
        &-btn-block {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          @media (max-width: $breakpoint-sm) {
            font-size: 16px;
          }
        }
        &-label {
          font: 500 18px/28px $primary-font;
        }
        &-btn {
          padding-left: 10px;
          height: 100%;
          svg {
            width: 25px;
            height: 25px;
            padding-bottom: 7px;
            path {
              stroke-width: 2;
            }
          }
        }
      }
      .tx-rate {
        width: 100%;
        color: #b4b4b4;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 10px;
        vertical-align: top;
        text-align: end;
      }
      .tx-fee {
        width: 100%;
        color: $main-color;
        // font-size: 12px;
        line-height: 30px;
        text-align: end;
      }
    }
    &-info__total-row {
      @include d-flex(space-between, flex-start);
      font: 700 20px/37px $primary-font;
      color: $main-color;
      .info-icon {
        margin-left: 7px;
        :hover {
          cursor: pointer;
        }
        svg {
          height: 20px;
          padding-bottom: 3px;
        }
      }
    }
    &__separate {
      background-color: $main-color;
      height: 0.5px;
      width: 100%;
      margin: 10px 0;
    }
    &-back-btn {
      font: 500 1rem/2 $primary-font;
      color: $main-color;
      margin-bottom: 5px;
      padding-left: 35px;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        display: block;
        left: 14px;
        top: 44%;
        transition: left 0.1s;
        border-color: $main-color;
        border-style: solid;
        border-width: 0 0 2px 2px;
        height: 12px;
        width: 12px;
        margin-top: -5px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}
