.footer {
  position: relative;
  @media (max-width: $breakpoint-sm) {
    display: flex;
    align-items: flex-end;
    height: 40px;
    &.h-90 {
      height: 90px;
    }
  }
}
.footer-links {
  @include d-flex($justify-content: center);
  position: relative;
  grid-gap: 10px;
  padding: 15px 0;
  .build-version {
    position: absolute;
    bottom: 10px;
    right: 5px;
    color: $disabled;
    font-size: 12px;
  }
  a {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    color: #fff;
    display: inline-block;
  }
  @media (max-width: $breakpoint-sm) {
    height: 100%;
    text-align: center;
    padding: 8px 0;
    a {
      font-size: 10px;
      font-weight: 500;
      color: $main-color;
    }
    strong {
      display: none;
    }
    &.hide-mobile {
      display: none;
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    a {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.poweredBy {
  color: $main-color;
  text-align: center;
  margin-top: 10px;
  font-size: 10px;
  .additional-logo {
    display: inline;
    width: 112px;
  }
  svg {
    width: 15px;
    height: 100%;
  }
  span {
    &:first-child {
      font-size: 11px;
      color: #bbbbbb;
      margin-right: 4px;
    }
    &:last-child {
      font-size: 8px;
      color: $text-color;
      font-weight: 900;
      margin-left: 2px;
    }
  }
}

.privacy {
  background: rgba(20, 10, 90, 0.75);
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  // position: absolute;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  .container {
    @include d-flex(center, center);
    max-width: 1440px;
  }
  h4 {
    font-size: 21px;
    font-weight: 400;
    min-width: 120px;
  }
  p {
    max-width: 450px;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    a {
      color: $white;
      text-decoration: underline;
    }
  }
  @media (max-width: $breakpoint-sm) {
    .container {
      // flex-direction: column;
      grid-gap: 10px;
      .btn {
        min-width: 91px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    .container {
      grid-gap: 20px;
    }
  }
}

.mobile-nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  nav {
    @include d-flex(space-between, center);
    .transactions-icon {
      position: relative;
      .transaction-count-icon {
        @include d-flex(center, center);
        position: absolute;
        top: -9px;
        left: 0;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background-color: #f80000;
        color: #ffffff;
        font-size: 10px;
        line-height: 15px;
      }
    }
  }
  a {
    @include d-flex(space-between, center);
    flex-direction: column;
    font-size: 10px;
    line-height: 12px;
    color: $main-color;
    width: 25%;
    text-align: center;
    &:last-child {
      width: 19%;
    }
    &.active {
      font-weight: 700;
    }
    svg {
      margin-bottom: 6px;
    }
  }
  @media (max-width: 250px) {
    padding: 20px 10px;
  }
}
