.send-block {
  max-width: 404px;
  background: $white;
  box-shadow: -12px 16px 40px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius-main;
  margin: 60px auto 0;
  padding: 25px 30px;
  &-title {
    @include d-flex();
    margin-bottom: $medium-margin;
    h3 {
      font: 400 24px/28px $primary-font;
      color: $text-color;
      padding-left: 14px;
      white-space: nowrap;
    }
  }
  &-amount-wrapper {
    // margin-bottom: $medium-margin;
  }
  &-amount {
    @include d-flex(space-between, center);
    padding: 10px 16px;
    background: $input-main-bg;
    border-radius: $border-radius-main;
    &.focus {
      background: #fafcff;
    }
    &.error {
      background: #fbdada;
    }

    &-text {
      height: 22px;
      font-size: 10px;
      padding-left: 12px;
    }

    .currency {
      position: relative;
      @include d-flex(flex-start, center);
      font: 500 24px/24px $primary-font;
      color: $text-color;
      width: 50%;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 100px;
        height: 41px;
        width: 1px;
        background-color: $white;
      }
      svg {
        margin-right: 4px;
        position: relative;
      }
    }
    input {
      font: 600 24px/1 $primary-font;
      padding: 0 2px 0 0;
      height: 24px;
      border: none;
      background-color: transparent;
      text-align: end;
      width: 50%;
    }
  }
  &-row {
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    background: $input-bg;
    border-radius: $border-radius-main;
    padding: 10px 16px;
    margin-bottom: 10px;
  }
  &__name {
    font: 500 16px/23px $primary-font;
    color: $grey-color;
    padding-bottom: 8px;
    width: 50%;
  }
  &__value {
    font: 500 20px/23px $primary-font;
    color: $grey-color;
    padding-bottom: 8px;
    width: 50%;
    text-align: end;
  }
  &__text {
    font: 500 12px/14px $primary-font;
    color: $grey-color;
    margin-bottom: 6px;
  }
  &__separator {
    position: absolute;
    top: 26px;
    left: 0;
    right: 0;
    width: 100%;
    height: 0.5px;
    background-color: #e0e0e0;
  }
  &__additional {
    @include d-flex(unset, center);
    gap: 8px;
    font: 400 16px/19px $primary-font;
    color: $text-color;
    margin-top: $large-margin;
    padding: 0 5px 0 10px;
    svg {
      min-width: 16px;
    }
    a {
      display: inline;
      color: inherit;
      text-decoration: underline;
    }
  }
  .poweredBy {
    display: none;
  }
  .form-input-error {
    bottom: -22px;
    font-size: 10px;
    // text-align: end;
  }
  @media (max-width: $breakpoint-sm) {
    padding-top: 40px;
    margin-top: 20px;
    .btn-wrapper {
      justify-content: center;
    }
  }
  @media (max-width: $breakpoint-xs) {
    display: flex;
    min-height: calc(100vh - 220px);
    min-height: calc(100dvh - 150px);
    box-shadow: none;
    padding: 10px 0;
    flex-direction: column;
    // @supports (-webkit-touch-callout: none) {
    //   min-height: calc(100vh - 225px);
    // }
    &.logIn {
      min-height: calc(100vh - 310px);
      min-height: calc(100dvh - 200px);

      //   @supports (-webkit-touch-callout: none) {
      //     //background-color: #E0E0E0;
      //     min-height: calc(100vh - 275px);
      //   }
    }
    // &.nonAcceptedUser {
    //   min-height: calc(100dvh - 150px);
    //   //   @supports (-webkit-touch-callout: none) {
    //   //     //background-color: #E0E0E0;
    //   //     min-height: calc(100vh - 205px);
    //   //   }
    // }
    .btn-wrapper {
      order: 7;
      flex: 1;
      width: 100%;
      align-items: flex-end;
      justify-content: center;
    }
    .poweredBy {
      display: block;
      width: 100px;
      padding-left: 10px;
      order: 6;
    }
    &-title {
      // order: 1;
    }
    &-amount-wrapper {
      // order: 2;
    }
    &-row {
      // order: 3;
      margin-bottom: 14px;
    }
    &__additional {
      order: 5;
      margin-top: 0;
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.receive-method {
  &-block {
    margin-bottom: 15px;
  }
  &-fee {
    min-height: 14px;
    font: 500 12px/14px $primary-font;
    padding: 0 12px;
    color: $grey-color;
    margin-bottom: 6px;
    text-align: end;
  }
  // @media (max-width: $breakpoint-xs) {
  //   &-block {
  //     order: 4;
  //   }
  // }
}
