.verify-block {
    margin-bottom: $large-margin + 2;
    position: relative;
    h4{
        font-size: 18px;
        line-height: 21px;
    }
    form{
        position: relative;
        text-align: center;
    }
    .form-input-error {
        text-align: center;
    }
    @media (min-width: $breakpoint-sm + 1){
        h4{
            max-width: 345px;
            margin: 0 auto;
            margin-bottom: $medium-margin + 2;
        }
    }
    @media (max-width: $breakpoint-sm) {
        flex: 1;
        h4{
            margin-bottom: 12px;
            margin-left: 16px;
        }
    }
}


.pincode-input-container {
    margin-top: 12px;
    @include d-flex($justify-content:center, $align-items: unset);
    grid-gap: 10px;
    input {
        color: $main-color;
        border-radius: $border-radius-main!important;
        border-color: transparent!important;
        width: 56px !important;
        height: 60px !important;
        font-size: 40px;
        @media (max-width: $breakpoint-sm){
            width: 52px !important;
            height: 60px !important;
        }
        @media (max-width: $breakpoint-xs) {
            width: 47px !important;
            height: 56px !important;
            font-size: 34px;
        }
    }
}
