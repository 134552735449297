.header {
  background-color: $header-bg;
  padding: 9px 0;
  //line-height: 60px;
  min-width: 320px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  .container {
    position: relative;
    grid-gap: 20px;
  }
  .btn-sm {
    margin: 10px 0;
  }
  @media (min-width: $breakpoint-sm + 1) {
    .container {
      @include d-flex(space-between);
    }
  }
  @media (max-width: $breakpoint-sm) {
    padding-top: 28px;
    &-mobile {
      display: none;
    }
    .container {
      @include d-flex($justify-content: space-between);
    }
    .btn-sm {
      margin: 7px 0;
    }
  }
}

.profile {
  @include d-flex($align-items: center);
  &-general-menu {
    margin-right: 15px;
    a {
      position: relative;
      color: $text-color;
      font-size: 18px;
      padding: 0 8px;
      border-right: 1px solid $text-color;
      transition: left 0.3s;
      &:hover {
        text-decoration: underline;
      }
      &:last-child {
        border-right: none;
      }
      &.active {
        text-shadow: 0 0 0.6px $text-color;
        text-decoration: underline;
      }
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    height: 60px;
  }
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}
.profile-block {
  .profile {
    @include d-flex($align-items: center);
    &-details {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &-name {
      max-width: 170px;
      width: 100%;
      color: $text-color;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-tel {
      font-size: 14px;
      line-height: 16px;
      color: #b4b4b4;
    }
    &-personal-menu {
      @include d-flex;
      grid-gap: 15px;
      a {
        color: #fff;
        border-bottom: 1px solid transparent;
        &.active {
          text-shadow: 0 0 0.65px #fff, 0 0 0.65px #fff;
          border-color: #fff;
        }
      }
    }
    &-general-menu {
      margin-right: 0;
    }
    &-home,
    &-links {
      display: none;
    }
    & > div {
      position: relative;
    }
    .btn-xs {
      font-size: 12px;
    }
    .verified-success {
      width: 55px;
    }
  }
  @media (max-width: $breakpoint-lg) {
    .profile {
      &-name {
        max-width: 110px;
      }
      &-general-menu {
        a {
          font-size: 16px;
          padding: 0 3px;
        }
      }
    }
  }
  @media (max-width: $breakpoint-sm) {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    width: 65%;
    min-width: 340px;
    display: grid;
    padding: 40px 20px 30px;
    background: #fff;
    margin-left: auto;
    transition: right 0.4s ease;
    &-wrapper {
      display: none;
      &.active {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(49, 113, 216, 0.2);
        z-index: 100;
      }
    }
    &.active {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      visibility: visible;
      z-index: 10;
      overflow-y: scroll;
      .build-version-mobile {
        position: absolute;
        bottom: 5px;
        right: 10px;
        color: $disabled;
        font-size: 12px;
      }
      .profile {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        grid-gap: 15px;
        &-personal-menu {
          display: none;
        }
        &-home {
          display: block;
          order: 0;
          svg {
            margin-right: 10px;
          }
          a {
            @include d-flex(flex-start);
            color: $main-color;
          }
        }
        &-links {
          @include d-flex(center, flex-end);
          width: 100%;
          order: 5;
          a {
            color: $main-color;
            font-size: 9px;
            font-weight: 500;
            padding: 0 3px;
          }
          strong {
            color: $main-color;
            font-weight: normal;
          }
        }
        &-details {
          order: 2;
          position: relative;
          align-items: flex-start;
          grid-gap: 4px;
          width: 100%;
          padding-right: 25px;
        }
        &-general-menu {
          order: 4;
          flex: 1;
          margin-top: 15px;
          a {
            width: 100%;
            margin: 5px 0;
            border-right: none;
            text-align: left;
          }
        }
        button {
          order: 3;
        }
        &-arrow {
          position: absolute;
          width: 25px;
          right: 0;
          top: calc(50% - 11px);
        }
        &-name {
          white-space: inherit;
          max-width: inherit;
          text-align: left;
        }
        &-tel {
          color: $main-color;
        }
      }
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    .profile {
      grid-gap: 15px;

      &-personal-menu {
        position: absolute;
        top: 95px;
        right: 20px;
        z-index: 10;
      }
    }

    .profileLink {
      display: none;
    }
  }
}

.logo {
  max-width: 200px;
  height: 54px;
  &-wrapper {
    display: flex;
    align-items: flex-end;
    .poweredBy {
      margin-left: 10px;
      margin-bottom: 10px;
      height: 14px;
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
  img {
    margin-top: 10px;
  }
  @media (max-width: $breakpoint-lg) {
    &-wrapper {
      .poweredBy {
        display: none;
      }
    }
  }
  @media (max-width: $breakpoint-sm) {
    max-width: 150px;
    height: 42px;
  }
}

.profile-btn {
  display: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media (max-width: $breakpoint-sm) {
    display: block;
    margin-left: 2px;
    line-height: 32px;
    &.active {
      position: fixed;
      right: 24px;
      top: 24px;
      z-index: 666;
      width: 20px;
      transition: background-color 0.4s;
      span {
        display: block;
        height: 2px;
        background-color: transparent;
        &:after,
        &:before {
          @include pseudo($left: 0, $top: 9px);
          background-color: $main-color;
          height: 2px;
          background-color: #fff;
        }
        &:after {
          transform: rotate(45deg);
        }
        &:before {
          transform: rotate(-45deg);
        }
      }
      svg {
        display: none;
      }
    }
    svg path {
      fill: $main-color;
    }
  }
}
