.title {
    &-blue {
        color: $main-color;
        font-weight: 600;
        text-align: center;
    }
    &-darkBlue {
        color: $text-color;
        font-weight: 700;
        text-align: center;
    }
}

.text {
    &-grey {
        color: $grey500;
    }
    &-blue {
        color: $main-color;
    }
    &-green{
        color: $green400;
    }
    &-red{
        color: $danger;
    }
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
    &-left {
        text-align: left;
    }
}

.fz {
    &-small {
        font-size: 14px;
        line-height: 16px;
    }
    &-xSmall {
        font-size: 12px;
    }
}


h1,
.page-title {        
    font-weight: 400;
    @media (max-width: $breakpoint-sm) {
        font-size: 24px;
        line-height: 32px;
    }
    @media (min-width: $breakpoint-sm + 1) {
        font-size: 34px;
        line-height: 40px;
    }
}

.page-subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

h2 {
    font-weight: 400;
    @media (max-width: $breakpoint-sm) {
        font-size: 20px;
        line-height: 24px;
    }
    @media (min-width: $breakpoint-sm + 1) {
        font-size: 24px;
        line-height: 34px;
        color: $white;
    }
}

h4 {    
    font-weight: 400;
    @media (max-width: $breakpoint-sm) {
        color: $text-color;
    }
    @media (min-width: $breakpoint-sm + 1) {
        font-size: 18px;
        line-height: 24px;
        color: $white;
    }
}

.terms {
    p {
        font-weight: 500;
    }
}
.empty-title {
    text-align: center;
    color: $white;
}

.italic {
  font-style: italic;
}