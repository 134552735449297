.login-page {
  height: calc(100% - 95px);
  display: flex;
  flex-direction: column;
  flex: 1;
  .dateOfBirth {
    h2 {
      margin-bottom: 20px;
    }
  }
  @media (min-width: $breakpoint-sm + 1){
    h4{
      margin-left: $medium-margin;
      margin-bottom: $medium-margin + 2;
    }
  }
  @media (max-width: $breakpoint-sm){
    h4{
        font-size: 18px;
        margin-top: $main-margin + 4;
        margin-bottom: $main-margin + 4;
        margin-left: $medium-margin;
    }
    @supports (-webkit-touch-callout: none) {
      min-height: calc(100vh - 235px);
    } 
  }
}

.login-form{
  flex: 1;
}