.launchTitle {  
  color: #fff;
  @include d-flex($justify-content: center);
  grid-gap: 10px;
  margin-bottom: 33px;
  @media (max-width: $breakpoint-sm) {
    font-size: 18px;
    color: $main-color ;
    svg {
      width: 30px;
      path:first-child{
        fill: $main-color;
      }
    }
  }
  @media (min-width: $breakpoint-sm + 1) {
    font-size: 24px;
  }
}

.launchForm {
  max-width: 460px;
  margin: 0 auto;
  .form-row {
    grid-gap: 10px;
    margin-bottom: 30px;
    div{
      width: 100%;
    }
  }
  // input::placeholder { color: #a5a5a5;}
  // input:-webkit-input-placeholder { color:#a5a5a5; }
  // input:-moz-placeholder { color:#a5a5a5; } /* firefox 19+ */
  // input:-ms-input-placeholder { color:#a5a5a5; } /* ie */
  input {
    border-bottom: none;
    &:not(.submit) {
      background-color: #fff;
      border-radius: 40px;
      padding: 15px 20px;
      color: $main-color;     
    }
  }
  .react-tel-input {
    width: 100%;
    .special-label {
      display: none;
    }
  }
  .submit {
    font-weight: 400;
    &:not([disabled]) {
      cursor: pointer;
    }
  }
  .form-input-error {
    left: 60px;
    font-size: 14px;
  }
  @media (max-width: $breakpoint-sm) {
    font-size: 18px;
    color: $main-color;
    .form-row {
      margin-bottom: 15px;
      svg{
        width: 30px;
        path:first-child{
          fill: $main-color;
        }
      }
    }
    input{
      &:not(.submit){
        background: #EFEFEF;
        padding: 7px 20px;
        color: $main-color;
      }
    }
    .form-input-error{
      font-size: 12px;
      width: auto;
      bottom: -19px;
    }
  }
}

.thanks {
  color: #fff;
  text-align: center;
  margin-top: 45px;
  p {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  @media (max-width: $breakpoint-sm) {
    color: $main-color;
  }
}