.confirmation-page {
  @include d-flex($justify-content: center);
  flex-direction: column;
  grid-gap: 30px;
  min-height: 231px;
  color: $white;
  .loading-text {
    padding: 0 15px;
  }

  @media (max-width: $breakpoint-sm) {
    flex: 1;
    padding-bottom: 10px;
    color: $main-color;
    .loading-text {
      padding: 0 25px;
    }
  }
}
