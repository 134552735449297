.common-rectangle {
    position: relative;
    overflow: hidden;
    border-radius: 19px;
    background-color: $white;
    padding: 30px 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    h4 {
        //margin-bottom: 5px;
    }
    @media (max-width: $breakpoint-sm){
        //padding: 10px 20px;
        overflow: inherit;
        padding: 0;
    }
}

.rectangle-transfer {
    .fz-small {
        margin-top: 15px;
        text-align: center;
        font-weight: 500;
    }
}