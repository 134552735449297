.custom-selector {
  margin-bottom: 24px;

  .error {
    background-color: #fbdada;
  }
  label {
    font-size: 15px;
    padding: 0 16px;
  }
}
