@font-face {
    font-family: 'Roboto';
    src: url("../../fonts/roboto-regular.woff2") format('woff2'),
    url("../../fonts/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url("../../fonts/roboto-medium.woff2") format('woff2'),
    url("../../fonts/Roboto-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url("../../fonts/roboto-bold.woff2") format('woff2'),
    url("../../fonts/Roboto-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
} 


@font-face {
    font-family: "AmpleSoftPro";
    src: url("../../fonts/AmpleSoftPro-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}