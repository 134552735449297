.faq-page {
  &-content {
    max-width: 1133px;
    border-top: 1px solid #daeaff;
    // padding-top: 15px;
    color: $white;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 20px 0 10px;
  }
  p {
    margin-bottom: 10px;
    text-align: justify;
    &.important-text {
      font-size: 20px;
    }
  }
  ol,
  ul {
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  li {
    text-align: justify;
    a {
      color: $white;
      text-decoration: underline;
    }
  }
  @media (max-width: $breakpoint-sm) {
    &-content {
      color: $main-color;
      h2 {
        font-size: 20px;
        margin: 15px 0 5px;
      }
      p {
        font-size: 14px;
        margin-bottom: 8px;
        &.important-text {
          font-size: 18px;
        }
      }
      li {
        font-size: 14px;
        a {
          color: $main-color;
        }
      }
    }
  }
}
