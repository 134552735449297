.beneficiary-account-warning {
  @include d-flex(unset, center);
  gap: 8px;
  font: 400 16px/19px $primary-font;
  color: $danger;
  margin-bottom: $large-margin;
  padding: 0 5px 0 10px;

  svg {
    min-width: 16px;

    & path {
      fill: $danger;
    }
  }

  a {
    color: $danger;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  // @media (max-width: $breakpoint-sm) {
  //   color: $warning-color-mobile;

  //   svg path {
  //     fill: $warning-color-mobile;
  //   }

  //   a {
  //     color: $warning-color-mobile;
  //   }
  // }
}
