  .countdown-content {
    max-width:  660px;
    margin: 0 auto 20px;
    color: white;
    font-family: AmpleSoftPro;
    .data-section {
      display: flex;
      justify-content: center;
      & > * {
        @include d-flex($justify-content: center);
        flex-direction: column;
      }
    }
    .separating {
      margin-top: -20px;
    }
    @media (max-width: $breakpoint-sm) {
      color: $main-color;
      .data-section {
        grid-gap: 10px;
      }
      .label {
        margin-top: -7px;
        font-size: 16px;
      }
      .time,
      .separating {
        font-size: 28px;
        line-height: 36px;
        font-weight: 600;
      }
    }
    @media (min-width: $breakpoint-sm + 1) {
      .data-section {
        grid-gap: 26px;
      }
      .data-block {
        min-width: 100px;
        &-seconds {
          min-width: 170px;
          text-align: left;
          .time {
            align-self: start;
          }
        }
      }
      .label {
        margin-top: -10px;
        font-size: 18px;
      }
      .time,
      .separating {
        font-size: 96px;
        line-height: 112px;
      }
    }
  }