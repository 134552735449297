@mixin d-flex($justify-content:space-between, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin pseudo($pos:absolute, $top: initial, $right:initial, $bottom: initial, $left: initial) {
  content: '';
  width: 100%;
  position: $pos;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  display: block;
  transition: .4s all;
}

@mixin height-100-with-iphone-fix {
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
  }
}