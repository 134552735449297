:root {
  --app-height: 100%;
}

html {
  height: stretch;
  height: -webkit-fill-available;
  .overflow-hidden {
    overflow: hidden;
  }
}
.text-error {
  color: $error-color;
}

body {
  color: $text-color;
  background-color: $white;
  min-width: 375px;
  -webkit-overflow-scrolling: touch;
  // touch-action: none;
  min-height: var(--app-height);
  height: -webkit-fill-available;
  //overflow: hidden;
  font: 16px/22px $primary-font, sans-serif;
  &:before {
    background-color: $white;
  }
  .danger {
    color: $danger;
    display: block;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .w-100 {
    width: 100%;
  }
  a {
    -webkit-tap-highlight-color: transparent;
  }

  .copy-field {
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    -webkit-tap-highlight-color: transparent;
  }
  .d-flex {
    display: flex;
  }

  .justify {
    &-center {
      justify-content: center;
    }
    &-between {
      justify-content: space-between;
    }
    &-end {
      justify-content: flex-end;
    }
  }
  @media (max-width: $breakpoint-xs) {
    .copy-field svg {
      height: 20px;
      width: 20px;
      path {
        fill: $main-color;
      }
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  // height: var(--app-height);
  background: $body-bg;
  &.bg-image {
    position: relative;
    background: $body-bg-img no-repeat;
    background-size: cover;
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .header,
  .footer {
    flex: 0 0 auto;
  }
  main {
    flex: 1 0 auto;
    position: relative;
    padding: 138px 0 40px;
  }
  @media (max-width: $breakpoint-sm) {
    background: $header-bg;
    min-height: initial;
    height: inherit;
    //overflow-y: scroll;
    &.bg-image {
      background: $header-bg;
      &:before {
        display: none;
      }
    }
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
    main {
      min-height: calc(100% - 90px);
      padding: 40px 0 0;
      &.main {
        padding-top: 90px;
      }
      &:not(.main-user) {
        min-height: calc(100% - 90px);
      }
      &:not(.main).main-user {
        min-height: calc(100% - 40px);
      }
      @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available;
      }
    }
  }
  &.blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  &-xxl {
    //width: 95%;
    max-width: 1380px;
  }
  &-md {
    max-width: 540px;
  }
  @media (max-width: $breakpoint-sm) {
    padding-left: 16px;
    padding-right: 16px;
    &-full-mb {
      max-width: 100%;
      padding: 0;
      //height: 100%;
    }
    &-full-h {
      height: 100%;
    }
    &-100 {
      display: flex;
      flex-direction: column;
    }
  }
}

main > section {
  @media (max-width: $breakpoint-sm) {
    &.h-100-mb {
      height: 100%;
      .container-100 {
        height: 100%;
      }
    }
  }
  @media (max-width: $breakpoint-lg) and (max-height: $breakpoint-xs) {
    height: auto;
  }
}

.grid {
  display: grid;
  position: relative;
  grid-gap: 20px;
  &-col2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $breakpoint-xs) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
}

.mainContent-footer {
  margin-top: 75px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: $border-color;
  &-outer {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}

body .cursor {
  &-default {
    cursor: default;
    label,
    label > *,
    input {
      cursor: default;
    }
  }
  &-notAllowed {
    cursor: not-allowed;
    label,
    label > *,
    input {
      cursor: not-allowed;
    }
  }
}

body .verified {
  width: max-content;
  &-success {
    @include d-flex;
    grid-gap: 5px;
    color: $green400;
    &.t-white {
      color: #fff;
    }
  }
  &-error {
    color: #fff;
  }
}

.identity-error {
  @include d-flex($justify-content: center);
  flex-direction: column;
  grid-gap: 15px;
  a {
    color: inherit;
    text-decoration: underline;
  }
  span {
    font: 400 22px/26px $primary-font;
    color: $white;
    text-align: center;
  }
  @media (max-width: $breakpoint-sm) {
    padding-top: 30%;
    span {
      font-size: 20px;
      color: $main-color;
    }
    svg {
      width: 80px;
      height: 80px;
      circle {
        fill: $main-color;
      }
      path {
        fill: $white;
      }
    }
  }
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

//margins
.mb {
  &-10 {
    margin-bottom: 10px;
  }
  &-20 {
    margin-bottom: 20px;
  }
  &-24 {
    margin-bottom: 24px;
  }
  &-30 {
    margin-bottom: 30px;
  }
}
.mt {
  &-20 {
    margin-top: 20px;
  }
  &-30 {
    margin-top: 30px;
  }
}

// search block\
.search-block {
  @include d-flex;
  position: relative;
  min-width: 200px;
  max-width: 400px;
  width: 100%;
  background-color: $input-bg;
  border-radius: $border-radius-main;
  max-height: 44px;
  cursor: pointer;
  input {
    height: auto;
    padding-right: 42px;
    font-size: 16px;
  }
  button {
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    margin: 0 10px;
    svg {
      //width: 24px;
      //height: 24px;
      path {
        fill: $main-color;
      }
      circle {
        fill: transparent;
      }
    }
  }
  @media (max-width: $breakpoint-sm) {
    //max-width: 256px;
    //max-height: 28px;
    button {
      margin: 0;
      right: 13px;
    }
    button svg {
      width: 16px;
      height: 16px;
    }
  }
}

// show-mobile
.show-mobile {
  display: none;
  @media (max-width: $breakpoint-sm) {
    display: block;
  }
}

//hide-mobile
.hide-mobile {
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}

.m {
  &-auto {
    margin: 0 auto;
  }
  &-20 {
    margin: 0 20px;
  }
  &-b10 {
    margin-bottom: 10px;
  }
  &-b20 {
    margin-bottom: 20px;
  }
  &-b30 {
    margin-bottom: 30px;
  }
}

.t-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

/* amount-block */
.calculation-block {
  max-width: 500px;
  width: 100%;
  margin-bottom: $medium-margin + 2;
}
.amount-block {
  @include d-flex();
  background-color: $input-main-bg;
  border-radius: $border-radius-main;
  padding: 8px 16px;
  &.focus {
    background: #fafcff;
  }
  &.error {
    background: $error-bg;
    color: $danger;
  }
  &__title {
    font: 500 18px/21px $primary-font;
    color: $text-color;
    flex: 1;
  }
  &__flag {
    margin-right: 12px;
    height: 24px;
  }
  &__input {
    max-width: 44%;
    input {
      width: 100%;
    }
    input::placeholder,
    input::-ms-input-placeholder,
    input::-ms-input-placeholder {
      font-size: 8px !important;
      color: red !important;
    }
    span {
      font: 500 24px/28px $primary-font;
      color: $main-color;
    }
  }
  &__flag {
    width: 28px;
  }
  @media (max-width: $breakpoint-sm) {
    &__input {
      span {
        display: none;
      }
    }
  }
}

//redeem-code-block

.redeem-code-block {
  @include d-flex();
  background: $input-main-bg;
  border-radius: $border-radius-main;
  margin-bottom: $main-margin;
  padding: 10px 15px;
  &__title {
    font: 400 16px/19px $primary-font;
    color: $main-color;
  }
  &__code {
    font: 700 24px/28px $primary-font;
    color: $text-color;
    flex: 1;
    text-align: end;
    padding-right: 10px;
  }
  &__icon {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    .copy-to-clipboard {
      position: relative;
      .rc-tooltip {
        min-width: 112px;
        top: 50%;
        transform: translateY(-50%);
        left: -120px;
      }
    }
  }
  &__status {
    @include d-flex(flex-start);
    font-size: 14px;
    line-height: 16px;
    gap: 5px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  @media (max-width: $breakpoint-sm) {
    &__icon {
      display: block;
    }
    &__code {
      flex: 1;
      text-align: end;
      padding-right: 7px;
    }
  }
}

//payment-block
.payment-block {
  @include d-flex(space-between);
  padding: 2px 8px 16px 16px;
  min-height: 46px;
  &__count {
    font: 400 24px/28px $primary-font;
    color: $main-color;
    span {
      font-weight: 700;
      padding-left: 3px;
    }
  }
  &__info {
    width: 24px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @media (min-width: $breakpoint-sm + 1) {
    &__count {
      color: $white;
    }
  }
}

//share-redeem-code
.share-redeem-code {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 13px 0;
  div {
    font: 700 20px/28px $primary-font;
    width: 100%;
  }
  button {
    @include d-flex(center, center);
    flex-direction: column;
    width: 33%;
    color: $main-color;
    svg {
      margin-bottom: 3px;
    }
    .redeem-code {
      @include d-flex(center, center);
      width: 34px;
      height: 34px;
      background-color: rgb(127, 127, 127);
      border-radius: 50%;
      svg {
        width: 22px;
        height: 24px;
        margin-bottom: 0;
        path {
          fill: $white;
        }
      }
    }
  }
}

//blinking
.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.fake-input {
  &-label {
    color: #ffffff;
    padding-left: 7px;
    font-size: 15px;
  }

  &-value {
    background-color: #eff6ff;
    color: $disabled;
    width: 100%;
    font-size: 18px;
    padding: 11px 16px;
    border-radius: 10px;
  }

  @media (max-width: $breakpoint-sm) {
    &-label {
      color: $main-color;
    }
  }
}
